import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenteroneUk from "../components/CenteroneUk.js";
import CenterUk from "../components/CenterUk.js";

function Uk() {
  return (
    <>
      <Header />
      <CenterUk />
      <CenteroneUk />
      <Footer />
    </>
  );
}

export default Uk;
