import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenteroneUae from "../components/CenteroneUae";
import CenterUae from "../components/CenterUae.js";

function Uae() {
  return (
    <>
      <Header />
      <CenterUae />
      <CenteroneUae />
      <Footer />
    </>
  );
}

export default Uae;
