
import React from 'react'

function CenterUae() {
  return (
    <section className="">
    <div className="container py-5 ">
      <div className="col-lg-10 col-md-9 offset-md-1">
 
        <div class=" lh-base">
          {/* <p className="p-0 m-0">Calculate your FX trading fees.</p> */}
          <ul className=" lh-lg">
            <li className="mt-3 " style={{listStyle:"none"}}>
            Trading forex (currencies) in the United Arab Emirates is popular among residents. While recommended, forex brokers are not required to become authorised by the Securities and Commodities Authority (SCA) or Dubai Financial Services Authority (DFSA) to accept residents of the United Arab Emirates as customers.
            </li>
         
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CenterUae