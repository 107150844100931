/* eslint-disable no-sparse-arrays */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import { useState } from "react";
import { Link } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import Instance from "../utils/Interceptor";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";

const ScamBroker = () => {
  const [searchValue, setSearchValue] = useState("");
  const [BrokerData, setBrokerData] = useState([]);
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [SelectCountry, setSelectedCountry] = useState();

  const countryCodes = {
    AF: "Afghanistan",
    AE: "United Arab Emirates",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    BH: "Bahrain",
    BD: "Bangladesh",
    BE: "Belgium",
    BR: "Brazil",
    CN: "China",
    CY: "Cyprus",
    DK: "Denmark",
    EG: "Egypt",
    FI: "Finland",
    FR: "France",
    DE: "Germany",
    HK: "Hong Kong",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JP: "Japan",
    KW: "Kuwait",
    LU: "Luxembourg",
    NL: "Netherlands",
    NZ: "New Zealand",
    OM: "Oman",
    PL: "Poland",
    QA: "Qatar",
    SA: "Saudi Arabia",
    SG: "Singapore",
    ZA: "South Africa",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    UAE: "United Arab Emirates",
    GB: "United Kingdom",
    PK: "AllCountry",
    US: "AllCountry",
    RU: "AllCountry",
    TR: "AllCountry",
    IN: "AllCountry",
    CA: "AllCountry",
    MX: "AllCountry",
    NG: "AllCountry",
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await Instance.get("api/country-code");
        const country = response.data.country;
        const fullCountry = countryCodes[country];
        const condition =
          fullCountry && Object.values(countryCodes).includes(fullCountry)
            ? fullCountry
            : "AllCountry";
        setSelectedCountry(condition);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching country code:", error);
      }

      try {
        setLoader(true);
        const response = await Instance.get("api/scam-broker");
        const brokerData = response.data.data;
        setBrokerData(brokerData);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching broker data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [BrokerData]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    filterBrokers(event.target.value);
  };

  const newdata = BrokerData?.filter((broker) =>
    broker?.broker?.country?.includes(SelectCountry)
  );
  console.log(newdata, "newdata");

  console.log(filteredBrokers, "filteredBrokers");
  const filterBrokers = (searchText) => {
    const filteredResults = BrokerData.filter((broker) =>
      broker.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBrokers(filteredResults);
  };

  const minRating = 0.1;
  const maxRating = 5.0;

  return (
    <>
      <Header />
      {Loader ? (
        <div className="Loader"></div>
      ) : (
        <>
          <div className="container">
            <div className="row d-flex-justify-content-center py-4">
              <div className="col-md-12">
                <div className="scam-con">
                  <h1
                    className="text-center py-2 heading-scam"
                    style={{ fontWeight: "700" }}
                  >
                    Check whether your broker is trusted by BrokerChooser
                  </h1>
                  <p className="text-center">
                    We all heard stories of people losing a lot of money to scam
                    brokers. We are here to help you find a legit and reliable
                    provider. Enter the name of a broker and see if it can be
                    trusted.
                  </p>
                </div>

                <div className="border1 py-2">
                  <h6 className="text-bold re">Enter broker name below</h6>
                  <div className="flex flex-col">
                    <input
                      type="text"
                      className="form-control scam"
                      placeholder="Search by Name"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                    <button className="btn btn-primary ms-2">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section
            className="brokerlist py-5"
            style={{ backgroundColor: "#ecf0f2" }}
          >
            <div className="container">
              <div className="row">
                <div className="row gy-5 gx-2 " style={{ marginLeft: "2px" }}>
                  {filteredBrokers.length === 0 && !searchValue && ""}
                  {!searchValue
                    ? ""
                    : filteredBrokers.map((e, i) => (
                        <div
                          key={i}
                          className="col-lg-4 pricing-block style-two animated fadeInUp"
                          data-wow-delay="200ms"
                          data-wow-duration="1200ms"
                        >
                          <div
                            className="inner-box"
                            style={{ border: "0px", paddingTop: "28px" }}
                          >
                            <div className="lower-content">
                              {e?.broker?.recommended === 2 ? (
                                <h5>{"Recommended"}</h5>
                              ) : (
                                <p>{""}</p>
                              )}
                              <h4 style={{ marginTop: "0px" }}>
                                <span
                                  onClick={() =>
                                    window.open(`${e?.broker?.url}`, "_blank")
                                  }
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={e?.img}
                                    width={"77"}
                                    height={"77"}
                                    style={{
                                      borderRadious: "10px",
                                      boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                                    }}
                                    alt=""
                                    srcset=""
                                    className="mb-2 rounded-2"
                                  />
                                </span>

                                <br />

                                {e?.name}
                              </h4>
                              <div className="d-flex py-3 gap-2 justify-content-center  align-items-center setgap flex-reverse ">
                                <h3
                                  className="mt-2"
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {e?.broker?.ratting}
                                </h3>
                                <div className=" d-flex justify-content-center">
                                  <div class="ratings">
                                    <div class="empty-stars"></div>
                                    <div
                                      className="full-stars"
                                      style={{
                                        width: `${
                                          ((e?.broker?.ratting - minRating) /
                                            (maxRating - minRating)) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  className={`py-2 mb-4`}
                                  style={{ height: "92px" }}
                                >
                                  <div className="grid grid-cols-6 text-sm">
                                    <div className="text-slate-600 text-left">
                                      {" "}
                                      {e?.points.map((n, i) => {
                                        return <li> {n?.description}</li>;
                                      })}
                                    </div>
                                    {/* <div className="mr-4 flex">
                                  {" "}
                                  {e?.min_deposit}
                                </div> */}
                                  </div>

                                  {/* <div className="mr-4 flex">
                                  {e?.max_leverage}{" "}
                                </div> */}
                                </div>
                              </div>

                              <div
                                className="link-btn hellof"
                                style={{ cursor: "pointer" }}
                              >
                                <span
                                  onClick={() =>
                                    window.open(`${e?.broker?.url}`, "_blank")
                                  }
                                  className="theme-btn btn-style-two cursor-pointer"
                                >
                                  <span className="btn-title">
                                    VISIT BROKER{" "}
                                    <LuExternalLink
                                      size="22"
                                      style={{ paddingBottom: "5px" }}
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="hint">
                                <span className="forhover  cursor-pointer">
                                  {" "}
                                  <Link
                                    to={e?.broker?.path}
                                    className="forhover"
                                    style={{
                                      borderBottom: "1px solid #0a2cd4",
                                    }}
                                  >
                                    Read review
                                  </Link>{" "}
                                </span>
                                <p className="tradepara py-1 mt-2 mb-0">
                                  {e?.broker?.lose}% of retail CFD accounts lose
                                  money
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  {searchValue.length === 0 ? (
                    ""
                  ) : (
                    <section className="about">
                      <div className="container py-5">
                        <div className="row world">
                          <h1 className="text-center">Suggested articles</h1>
                          <div className="col-md-4  d-flex flex-column gap-2 mt-3">
                            <div className="bg-box ">
                              <h4 className="text-center py-2">
                                How to choose a safe broker: a detailed guide
                              </h4>
                              <p
                                className="text-center py-2"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "300",
                                  color: "#000",
                                  lineHeight: "29px",
                                }}
                              >
                                The most critical factor is choosing a
                                well-regulated broker. Learn how investor
                                protection works.{" "}
                              </p>
                              <div className="link-btn d-flex justify-content-center rounded-0">
                                <Link
                                  to="/whatisforex"
                                  className="theme-btn faiz rounded-0"
                                >
                                  <span className="faiz1">
                                    LEARN MORE
                                    <AiOutlineArrowRight />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4  d-flex flex-column gap-2 mt-3">
                            <div className="bg-box ">
                              <h4 className="text-center py-2">
                                Investor protection schemes are national
                                initiatives established by governments or
                                industry organizations to safeguard investors
                                from potential losses arising from fraudulent or
                                unethical practices.
                              </h4>
                              <p
                                className="text-center py-2"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "300",
                                  color: "#000",
                                  lineHeight: "29px",
                                }}
                              >
                                Foreign exchange, or forex, traders speculate on
                                changing exchange rates by converting large sums
                                of money from currency to currency, much like
                                stock traders buy and sell different stocks.
                                Forex traders essentially attempt to buy low and
                                sell high for a profit, but the asset they are
                                trading is currency.{" "}
                              </p>
                              <div className="link-btn d-flex justify-content-center rounded-0">
                                <Link
                                  to="/whatisforex"
                                  className="theme-btn faiz rounded-0"
                                >
                                  <span className="faiz1">
                                    LEARN MORE
                                    <AiOutlineArrowRight />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4  d-flex flex-column gap-2 mt-3">
                            <div className="bg-box ">
                              <h4 className="text-center py-2">
                                Forex trading scams - an expert guide to
                                avoiding fraud
                              </h4>
                              <p
                                className="text-center py-2"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "300",
                                  color: "#000",
                                  lineHeight: "29px",
                                }}
                              >
                                Foreign exchange, or forex, traders speculate on
                                changing exchange rates by converting large sums
                                of money from currency to currency, much like
                                stock traders buy and sell different stocks.
                                Forex traders essentially attempt to buy low and
                                sell high for a profit, but the asset they are
                                trading is currency.{" "}
                              </p>
                              <div className="link-btn d-flex justify-content-center rounded-0">
                                <Link
                                  to="/whatisforex"
                                  className="theme-btn faiz rounded-0"
                                >
                                  <span className="faiz1">
                                    LEARN MORE
                                    <AiOutlineArrowRight />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {searchValue && filteredBrokers.length === 0 && (
                    <p>We couldn`t find any brokers based on your search.</p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
};

export default ScamBroker;
