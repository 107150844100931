import React from 'react'

function CenterUk() {
  return (
    <section className="">
    <div className="py-5  ">
      <div className="col-lg-10 col-md-9 offset-md-1">
      <div className="container" style={{paddingLeft:"80px"}}>

       <h1
            className="feeCalculator-heading fw-bold pt-2 py-3"
                
            style={{ color: "black",marginLeft:"30px" }}
          >
Best UK Forex Brokers of 2024
          </h1> 
        </div>
        <div className="container" style={{paddingLeft:"80px"}}>
          <ul className=" lh-lg">
            <li className="mt-3 " style={{listStyle:"none"}}>
            Trading forex (currencies) in the United Kingdom (UK) is popular among residents. Before any fx broker can accept UK forex and CFD traders as clients, they must become authorised by the Financial Conduct Authority (FCA), which is the financial regulatory body in the UK. The FCA’s website is <a href='FCA.org.uk'> FCA.org.uk.</a> We recommend UK residents also follow the FCA on twitter, <a href='https://twitter.com/TheFCA'>@TheFCA.</a> 
            </li>
            <li className="mt-3" style={{listStyle:"none"}}>
            The FCA was formed out of the Financial Services Act of 2012, effectively replacing its predecessor, the Financial Services Authority (FSA). For a historical breakdown, here’s a link to Financial Conduct Authority webpage on Wikipedia.


            </li>
       
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CenterUk