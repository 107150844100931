/* eslint-disable react/jsx-pascal-case */

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Allreview from "./pages/Allreview";
import CompareBrokermain from "./pages/CompareBrokermain";
import NewCompareBroker from "./pages/NewCompareBroker";
import FeeCalculator from "./pages/FeeCalculator";
import ReviewDetail from "./pages/ReviewDetail";
import Activetrades from "./pages/Activtraders-review";
import Xtb_review from "./pages/Xtb-review";
import IGreview from "./pages/IG-review";
import PapperStone from "./pages/Pepperstone-review-2";
import Forex_Com from "./pages/Forex-com";
import Fxpro from "./pages/Fxpro-review";
import City_index from "./pages/City-index-review";
import Cmc_markets from "./pages/Cmc-markets-review";
// import MyComponent from './components/Check';
import Whatforex from "./pages/WhatisForex";
import Whatcfd from "./pages/Whatiscfd";
import ScamBroker from "./pages/Scam-Broker";
// import Hycm from './pages/Hycm_review';
import Hycm_review from "./pages/Hycm_review";
// import Markets_review from './pages/Marketsreview';
import Marketsreview from "./pages/Marketsreview";
import Newpage from "./pages/Newpage";
import Germany from "./pages/Germany";
import Singapore from "./pages/Singapore";
import Uae from "./pages/Uae";
import Uk from "./pages/Uk";
import Whatiscurency from "./pages/Whatiscurrency";
import Whatispip from "./pages/Whatispip";
import Howtostartforex from "./pages/Howtostartforex";
import Laverage from "./pages/Whotardes";
import ForexWho from "./pages/Whotradeforex";
import Tickmill_review from "./pages/Tickmill_review";
import Oanda_Review from "./pages/Oanda_Review";
import Admirals_Review from "./pages/Admirals_Review";
import EToro_Review from "./pages/EToro_Review";
import Capital_com_Review from "./pages/Capital_com_Review";
import Swissquote_Review from "./pages/Swissquote_Review";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/newpage" element={<Newpage />}></Route>
        <Route path="/germany" element={<Germany />}></Route>
        <Route path="/singapore" element={<Singapore />}></Route>
        <Route path="/uae" element={<Uae />}></Route>
        <Route path="/uk" element={<Uk />}></Route>
        <Route path="/plus500-review" element={<ReviewDetail />}></Route>
        <Route path="/allreviews" element={<Allreview />}></Route>
        <Route path="/comparebroker" element={<NewCompareBroker />}></Route>
        <Route path="/comparebroker2" element={<CompareBrokermain />}></Route>
        <Route path="/scam-broker-shield" element={<ScamBroker />}></Route>
        <Route path="/compare-spread" element={<FeeCalculator />}></Route>
        <Route path="/compare-spread" element={<FeeCalculator />}></Route>
        <Route
          path="/what-is-currency-trading"
          element={<Whatiscurency />}
        ></Route>
        <Route path="/what-is-a-pip" element={<Whatispip />}></Route>
        <Route path="/who-trades-forex" element={<ForexWho />}></Route>
        <Route
          path="/how-to-start-forex-trading"
          element={<Howtostartforex />}
        ></Route>
        <Route path="/activtraders-review" element={<Activetrades />}></Route>
        <Route path="/xtb-review" element={<Xtb_review />}></Route>
        <Route path="/ig-review" element={<IGreview />}></Route>
        <Route path="/pepperstone-review" element={<PapperStone />}></Route>
        <Route path="/forex-com-review" element={<Forex_Com />}></Route>
        <Route path="/fxpro-review" element={<Fxpro />}></Route>
        <Route path="/city-index-review" element={<City_index />}></Route>
        <Route path="/cmc-markets-review" element={<Cmc_markets />}></Route>
        <Route path="/whatisforex" element={<Whatforex />}></Route>
        <Route path="/what-is-cdf" element={<Whatcfd />}></Route>
        <Route path="/what-is-leverage" element={<Laverage />}></Route>
        <Route path="/hycm-review" element={<Hycm_review />}></Route>
        <Route path="/markets-review" element={<Marketsreview />}></Route>
        <Route path="/tickmill-review" element={<Tickmill_review />}></Route>
        <Route path="/oanda-review" element={<Oanda_Review />}></Route>
        <Route path="/admiral-review" element={<Admirals_Review />}></Route>
        <Route path="/etoro-review" element={<EToro_Review />}></Route>
        <Route
          path="/capital-com-review"
          element={<Capital_com_Review />}
        ></Route>
        <Route
          path="/swissquote-review"
          element={<Swissquote_Review />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
