/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
import React from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import { ImLinkedin2 } from "react-icons/im";
const Footer = () => {
  return (
    <>
      <section className="bg-dark py-5">
        <div className="container py-2">
          <div className="row">
            <div className="col-md-4">
              <h6 className="text-light-gray text-left border-bottom pb-2 mb-3">
                Brokers Review
              </h6>
              <ul className="list-unstyled text-light">
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Plus500
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    ActivTrades
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    XTB
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    IG
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Pepperstone
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Forex.com
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Fxpro
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    City Index
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    CMC Markets
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Tickmill
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Oanda
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <h6 className="text-light-gray text-left border-bottom pb-2 mb-3">
                Country specific recommendations
              </h6>
              <ul className="list-unstyled text-light">
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    United States
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    United Kingdom
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    India
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Singapore
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Australia
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Malaysia
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Canada
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    South Africa
                  </a>
                </li>

                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Germany
                  </a>
                </li>
                <li className="my-3 text-left">
                  <a className="text-light text-decoration-none" href="">
                    Netherlands
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <h6 className="text-light-gray text-left border-bottom pb-2 mb-3">
                Regional settings
              </h6>
              <div className="text-light text-left">
                <a
                  className="text-light text-decoration-none text-left"
                  href=""
                  style={{
                    textAlign: "left",
                  }}
                >
                  Automatic (Pakistan)
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="py-5 text-white bg-darker font-sans relative z-25">
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <p class="text-sm text-secondary text-left">
                <strong>Disclaimer:</strong>
                <br />
                Please note that by investing in and/or trading financial
                instruments, commodities, and any other assets, you are taking a
                high degree of risk and you can lose all your deposited money.
                You should engage in any such activity only if you are fully
                aware of the relevant risks. does not provide investment or any
                other advice, for further information please read our
                <a href="" class="text-secondary text-decoration-none">
                  General Terms and Conditions
                </a>
                .
              </p>
            </div>
            <div class="col-sm-6"></div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
