import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenteroneSignapore from "../components/CenteroneSignapore";
import CenterSingapore from "../components/CenterSingapore";

function Singapore() {
  return (
    <>
      <Header />
      <CenterSingapore />
      <CenteroneSignapore />
      <Footer />
    </>
  );
}

export default Singapore;
