/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import city from "../assets/img/brokerlogoclient/City Index logo.png";
import { TiTick } from "react-icons/ti";
import { LuExternalLink } from "react-icons/lu";

const City_index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const minRating = 0.1;
  const maxRating = 5.0;
  return (
    <>
      <Header />
      <div className="container py-4 px-4">
        <div className="col-lg-10 col-md-9 offset-md-1">
          <h3 className="pt-4 pb-4  broker-name">City Index Review</h3>
          <div className="row  pt-5 pb-5 text-center">
            <div
              className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box1 py-4 px-4 ">
                <h4 className="mt-2">
                  <a href="" target="_blank">
                    <img
                      src={city}
                      width={"77"}
                      height={"77"}
                      alt="Picture not Found"
                      srcset=""
                      className="mb-2 rounded-2"
                      style={{
                        boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                      }}
                    />
                  </a>
                </h4>
                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">
                  <div className=" d-flex justify-content-center">
                    <div class="ratings">
                      <div class="empty-stars"></div>
                      <div
                        className="full-stars"
                        style={{
                          width: `${
                            ((5 - minRating) / (maxRating - minRating)) *
                            91.8367
                          }%`,
                        }}
                      ></div>
                    </div>
                    &nbsp;&nbsp;
                    <ul className="list-unstyled ptusa">
                      <li> 4.6</li>
                    </ul>
                  </div>
                </div>

                <div>
                  <div className={`py-0`}>
                    <div className="grid grid-cols-12 text-sm">
                      <div className="text-left lh-lg">
                        <ul className="list-unstyled">
                          <li>
                            <TiTick />
                            <strong> Min. Deposit: </strong> $150
                          </li>

                          <li className="fs-6">
                            {" "}
                            <TiTick />
                            <strong>Max. Leverage: </strong>30:1
                          </li>
                          <li className="fs-6">
                            {" "}
                            <TiTick />
                            <strong>Headquarter: </strong> London, UK
                          </li>
                          <li className="fs-6">
                            {" "}
                            <TiTick />
                            <strong>Products (CFD):</strong> Forex, Shares,
                            Commodities, Indices, Bonds, ETFs
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-1  my-1 btn-style-two1">
                  <a
                    href=""
                    target="_blank"
                    className=" w-100 text-align-center text-light"
                  >
                    <span className="">Visit City Index </span>
                    <LuExternalLink
                      color="#fff"
                      size="25"
                      style={{ paddingBottom: "5px" }}
                    />
                  </a>
                </div>

                <div className="hint">
                  <p className="tradepara1 py-2">
                    70% of retail CFD accounts lose money
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row lg-pt-5 lg-px-5">
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                U.K. brokerage City Index’s parent company is StoneX Group Inc.
                (NASDAQ:SNEX). It specializes in CFDs, spread betting, and
                options in forex, indexes, shares, commodities, bonds, interest
                rates, and cryptocurrencies. City Index was founded in 1983 and
                offers an impressive product catalog, excellent platform
                choices, and robust research facilities that cater to a broad
                spectrum of traders and investors, from low-skilled retailers to
                well-financed institutions looking for sophisticated hookups and
                a lightning-fast trading interface.
                <br />
                <br />
                City Index scored well in most review categories, but flaws are
                still evident, including confusing and conflicting options-fee
                disclosures, a lack of volume discounts, and no secondary
                insurance coverage..
              </p>
            </div>

            <div className="col-lg-12 py-5">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h5 className="pb-1 font-bold">Pros</h5>
                  <ul className="lh-lg" style={{ listStyle: "none" }}>
                    <li>✅ Low fees for forex</li>
                    <li>✅ Diverse research tools</li>
                    <li>✅ Large product catalog</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h5 className="pb-1 font-bold">Cons</h5>
                  <ul className="lh-lg" style={{ listStyle: "none" }}>
                    <li>❌Product portfolio limited to forex and CFDs</li>
                    <li>❌High stock CFD fees</li>
                    <li>❌ Non-user-friendly desktop platform</li>
                  </ul>
                </div>
              </div>
            </div>
            <h2 className="pt-4 pb-4 font-bold">Trust </h2>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                The broker is licensed through Financial Conduct Authority (FCA)
                Ref No. 113942 and is compliant with European Securities and
                Markets Authority (ESMA) rules that limit leverage and provide
                negative balance protection (so you don’t lose more money than
                you put in the platform). An ESMA-mandated disclosure indicates
                that 72% of retail accounts currently lose money when trading
                CFDs, which is about average in the Eurozone. The U.K. Financial
                Services Compensation Scheme (FSCS) provides up to £50,000
                coverage in case of a broker default, but they list no
                additional or private coverage.
                <br />
                <br />
                City Index offers guaranteed stop-loss protection for a premium
                as well as a sophisticated set of conditional orders within
                their trading platforms. Oddly, the firm’s small Singapore
                branch utilizes two-factor authentication for added security,
                but this useful feature wasn’t found at the U.K. site. The
                broker acts primarily as a market maker, like most European CFD
                brokers, raising conflict of interest issues. However, they
                offer central clearing on “certain markets” as a free service
                that requires an additional client application.
                <br />
                <br />
                Fee and spread disclosures provide extensive transparency, but
                it was difficult to find options costs. Information on fee
                schedules was conflicting, and it was hard to find cost
                comparisons. They offer fixed and/or variable spreads, depending
                on venue, but the disclosures would greatly benefit from
                side-by-side cost itemization. It also isn’t clear how much
                spreads widen during off-market hours, raising the potential for
                unanticipated trading costs
              </p>
            </div>

            <h2 className="pt-4 pb-4 font-bold">Desktop Experience</h2>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                The broker’s proprietary Web Trader offers advanced charts,
                custom indicators, and fully customizable workspaces, but it
                does have fewer features and less customization than the
                downloadable AT Pro platform. The AT Pro platform includes
                advanced technical analysis with more than 100 indicators,
                detachable charts, and strong customization. Real-time Reuters
                news in both platforms keep account holders in touch with
                business developments, while AT Pro’s historical back testing
                and automated, coded trading add firepower rarely found at
                European brokerages. In-platform access to dozens of trading
                ideas round out an impressive list of advanced features.
                <br />
                <br />A side-by-side comparison of account types shows that AT
                Pro is only available to professional traders (an ESMA
                designation that requires a large account and industry
                experience). This information conflicts with other site
                disclosures and contact with a sales agent through online chat
                confirmed that professional status isn’t required to use the
                software.
              </p>
              <h2 className="pt-4 pb-4 font-bold">Mobile Experience</h2>
              City Index mobile versions for iOS and Android allow easy synching
              between platforms, with well-organized menus but fewer features
              and limited customization. Account holders can set custom price
              alerts, build watch lists, chat with customer service, and do
              basic charting, but the apps don’t provide a real alternative to
              the standalone or web platforms. Fortunately, the order interface
              looks identical to the desktop and web programs, allowing ade
            </div>

            <h2 className="pt-4 pb-4 font-bold">Research Tools</h2>
            <div className="col-lg-12">
              <p>
                City Index has built an impressive research portal, with
                in-house analysts providing daily market outlooks and updates
                through a tag-based news page that’s full of fundamental and
                technical reports. The site also posts some news videos on
                YouTube, but poor integration between YouTube and website video
                content makes it more difficult to find topics of interest.
                Other research material is well organized, and many categories
                are also accessible through the trading platforms.
                <br />
                <br />
                Dozens of trading ideas and opportunities utilizing diverse
                market approaches are listed at the site and in-platform, but
                some content requires paid subscriptions. Platform research tabs
                open into Trading Central, which is divided between customizable
                technical and fundamental scanners. The fundamental scanner
                provides a wealth of filters and includes current prices vs. the
                broker’s calculation of fair value.
              </p>
            </div>

            <h2 className="pt-4 pb-4 font-bold">Education</h2>

            <p>
              An education section features written how-to-trade tutorials that
              are organized into eight sections. However, just four of the six
              open to standard course materials while the others duplicate
              research or marketing content found in other sections. A YouTube
              review found an excellent series of TA videos but none were linked
              directly to the website. The broker also provides how-to articles
              on each of the major trading venues as well as a full-featured
              help and support database. .
            </p>
            <h2 className="pt-4 pb-4 font-bold">Special Features</h2>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                The City Index API appears restricted to institutional and
                white-label partnerships, lowering the value of the AT Pro
                backtesting and automated trading capacities. In addition, they
                offer no VPS hosting, missing an obvious business opportunity.
                No trading volume discounts were found, discouraging large-sized
                and higher skill traders from opening accounts, despite Premium
                and Professional tiers that provide secondary bonuses.
                <br />
                <br />
                The broker offers central counterparty clearing on “certain
                markets” through the Omnibus Segregated Clearing Account (OSCA).
                This isn’t the same as direct market access into the interbank
                system because the broker still operates a dealing desk, first
                executing the trade and then transferring the client’s credit
                exposure to the central clearinghouse. A separate application is
                required to gain access to this account functionality.
              </p>
              <h2 className="pt-4 pb-4 font-bold">Investment Products </h2>
              <p>
                An impressive product catalog includes forex through FX via MT4,
                CFDs, and spread bets as well as CFDs, spread bets, and options
                on indexes, shares, commodities, metals, bonds, interest rates,
                and cryptocurrencies. Clients can trade more than 4,700 shares
                from multiple exchanges while index and metal CFDs can be traded
                on a cash or futures basis. Cryptocurrency coverage is limited
                to seven major markets.
              </p>

              <h2 className="pt-4 pb-4 font-bold">Commissions and Fees</h2>
              <p>
                Minimum and average spreads are competitive, at 0.50 pips
                minimum and 0.69 pips average for EUR/USD. Indexes list a 0.40
                minimum spread on S&P 500 CFD and spread bets but no average
                spread. Disclosures on other markets are limited to minimum
                spreads or real-time bid/ask spreads.
                <br />
                <br />
                The broker offers fixed spreads for many indexes and commodities
                and variable spreads for forex and other markets, but read the
                fine print because this categorization isn’t intuitive.
                <br />
                <br />
                Withdrawals are free and no minimum deposit is needed to open an
                account. However, £12 per month is charged to accounts with no
                activity for one year or longer. Withdrawal and other fees are
                fully disclosed on an easy-to-read pricing page that contained
                few omissions.
              </p>
            </div>
            <h2 className="pt-4 pb-4 font-bold">Safety</h2>
            <p>City Index is regulated in several jurisdictions including:</p>
            <div className="col-lg-12">
              <ul>
                <li>
                  In Canada by Canadian Investment Regulatory Organization
                  (CIRO)
                </li>
                <li>In UK by Financial Conduct Authority (FCA)</li>
                <li>
                  In Cyprus by Cyprus Securities & Exchange Commission (CySEC)
                </li>
                <li>In Japan by The Financial Services Agency (FSA)</li>
                <li>
                  In USA by The National Futures Association (NFA) and
                  Commodities Futures Trading Commission (CFTC)
                </li>
                <li>
                  In Singapore by The Monetary Authority of Singapore (MAS)
                </li>
                <li>
                  In Cayman Islands by Cayman Islands Monetary Authority (CIMA)
                </li>
                <li>
                  In Australia by The Australian Securities and Investments
                  Commission (ASIC)
                </li>
                <li>
                  In Hong Kong by The Securities and Futures Commission (SFC)
                </li>
              </ul>
            </div>
            <h2 className="pt-4 pb-4 font-bold">Customer Support</h2>
            <p>
              Customer support hits all the bases, with 24/5 phone support
              through London local and international phone numbers. Online chat
              is available to new and prospective clients, but users need to
              “talk” with an annoying chat robot before getting to a human
              customer representative. The broker accepts support questions
              through phone, chat, or email, and a fairly strong social media
              presence is used primarily to distribute research and marketing
              materials. The help and support FAQs are well-organized, allowing
              for quick retrieval of needed information.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default City_index;
