import React from "react";

function Center() {
  return (
    <>
      <section className="">
        <div className="container py-5 ">
          <div className="col-lg-10 col-md-9 offset-md-1">
            <div className="top-heading d-flex ">
              {/* <div className="d-flex align-items-center">
                      <p className="feeCalculator-new px-2 mx-2 fw-bold">NEW</p>
                    </div> */}

              <h1
                className="feeCalculator-heading fw-bold pt-2 py-3"
                style={{ color: "black" }}
              >
                Best Forex Brokers in Australia for 2024
              </h1>
            </div>

            <div class=" lh-base">
              {/* <p className="p-0 m-0">Calculate your FX trading fees.</p> */}
              <ul className="feeCalculator-list lh-lg">
                <li className="mt-3">
                  Though it’s roughly the size of the U.S., Australia has fewer
                  people than the state of Texas, and this relatively small
                  population benefits from a robust financial regulatory
                  environment and a sturdy banking system. Trading forex
                  (currencies) in Australia is popular among residents as well
                  as international traders seeking an Australian-based broker.
                </li>
                <li className="mt-3">
                  All Australia-based forex brokers must become authorised by
                  the Australian Securities & Investment Commission (ASIC), the
                  Australian financial markets regulator. ASIC’s website is{" "}
                  <a href="https://asic.gov.au/"> asic.gov.au.</a> We recommend
                  users also follow ASIC on Twitter,
                  <a href="https://twitter.com/ASIC_Connect">@ASIC_Connect.</a>
                </li>
                <li className="mt-3">
                  After the Australian Securities Commission (founded in 1991)
                  was dissolved, ASIC was established in 1998 as a national
                  regulatory authority which reports to the treasurer and
                  administers legislation for the Insurance Act of 1984, the
                  Corporations Act of 2001, and the National Consumer Credit
                  Protection Act of 2009. For a historical breakdown, here’s a
                  link to ASIC’s webpage on{" "}
                  <a href="https://en.wikipedia.org/wiki/Australian_Securities_and_Investments_Commission">
                    Wikipedia.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Center;
