import React, { createContext, useState } from "react";
export const LoaderContext = createContext();
export default function LoaderProvider({ children }) {
  // const [AsLoadder, setAsLoader] = useState(false)
  const [data, setData] = useState("faiz");
  return (
    <LoaderContext.Provider value={{ data, setData }}>
      {children}
    </LoaderContext.Provider>
  );
}
