import React, { useEffect } from "react";

const ProgressBar = ({ widthPercentage, backgroundColor }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const progressFillStyle = {
    width: `${widthPercentage}%`,
    background: backgroundColor,
  };

  return (
    <div className="progress-bar">
      <div className="progress-fill" style={progressFillStyle}></div>
    </div>
  );
};

export default ProgressBar;
