/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Whatcfd = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="container py-4 px-5">
        <div className="col-lg-10 col-md-9 offset-md-1">
          <h2 className="pt-4 pb-4  ">
            What is CFDs (Contract for Differences)
          </h2>

          <div className="row lg-pt-5 lg-px-5">
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                A contract for differences (CFD) is a contract between a buyer
                and a seller that stipulates that the buyer must pay the seller
                the difference between the current value of an asset and its
                value at contract time. The value of a CFD does not consider the
                asset’s underlying value, only the price change between the
                trade entry and exit.
              </p>
              <p className="lh-base fs-6">
                This is accomplished through a contract between client and
                broker and does not utilize any stock, forex, commodity, or
                futures exchange. Trading CFDs offers several major advantages
                that have increased the instruments’ enormous popularity in the
                past decade.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">How CFDs Work</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                A contract for differences (CFD) is an agreement between an
                investor and a CFD broker to exchange the difference in the
                value of a financial product (securities or derivatives) between
                the time the contract opens and closes.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                It is an advanced trading strategy that is utilized by
                experienced traders only. There is no delivery of physical goods
                or securities with CFDs. A CFD investor never actually owns the
                underlying asset but instead receives revenue based on the price
                change of that asset. For example, instead of buying or selling
                physical gold, a trader can simply speculate on whether the
                price of gold will go up or down. .
              </p>
              <p className="lh-base fs-6">
                Essentially, investors can use CFDs to place orders about
                whether or not the price of the underlying asset or security
                will rise or fall. Traders can bet on either upward or downward
                movement. If the trader who has purchased a CFD sees the asset’s
                price increase, they will offer their holding for sale. The net
                difference between the purchase price and the sale price are
                netted together. The net difference representing the gain from
                the trades is settled through the investor’s brokerage account.
                .
              </p>
              <p className="lh-base fs-6">
                On the other hand, if the trader believes that the asset’s value
                will decline, an opening sell position can be placed. To close
                the position, the trader must purchase an offsetting trade.
                Then, the net difference of the loss is cash-settled through
                their account. .
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Countries Where You Can Trade CFDs</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                CFDs are not allowed in the United States. They are allowed in
                listed, over-the-counter (OTC) markets in many major trading
                countries, including the United Kingdom, Germany, Switzerland,
                Singapore, Spain, France, South Africa, Canada, New Zealand,
                Sweden, Norway, Italy, Thailand, Belgium, Denmark, and the
                Netherlands, as well as the Hong Kong special administrative
                region.
              </p>
              <p className="lh-base fs-6">
                As for Australia, where CFDs are currently allowed, the
                Australian Securities and Investments Commission (ASIC) has
                announced some changes in the issue and distribution of CFDs to
                retail clients. ASIC’s goal is to strengthen consumer
                protections by reducing CFD leverage available to retail clients
                and targeting CFD product features and sales practices that
                amplify retail clients’ CFD losses. ASIC’s product intervention
                order took effect on March 29, 2021.
              </p>
              <p className="lh-base fs-6">
                The U.S. Securities and Exchange Commission (SEC) has restricted
                the trading of CFDs in the U.S., but nonresidents can trade
                using them.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">The Costs of CFDs</h3>

            <div className="col-lg-12">
              <p className="lh-base fs-6">
                The costs of trading CFDs include a commission (in some cases),
                a financing cost (in certain situations), and the spread—the
                difference between the bid price (purchase price) and the offer
                price at the time you trade.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                There is usually no commission for trading forex pairs and
                commodities. However, brokers typically charge a commission for
                stocks. For example, the broker CMC Markets, a U.K.-based
                financial services company, charges commissions that start from
                0.10%, or $0.02 per share, for U.S.- and Canadian-listed
                shares.5 The opening and closing trades constitute two separate
                trades, and thus you are charged a commission for each trade.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                A financing charge may apply if you take a long position. This
                is because overnight positions for a product are considered an
                investment (and the provider has lent the trader money to buy
                the asset). Traders are usually charged an interest charge on
                each of the days that they hold the position.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                For example, suppose that a trader wants to buy CFDs for the
                share price of GlaxoSmithKline. The trader places a £10,000
                trade. The current price of GlaxoSmithKline is £23.50. The
                trader expects that the share price will increase to £24.80 per
                share. The bid-offer spread is 24.80–23.50.
              </p>
            </div>

            <div className="col-lg-12">
              <p className="lh-base fs-6">
                The trader will pay a 0.1% commission on opening the position
                and another 0.1% when the position is closed. For a long
                position, the trader will be charged a financing charge
                overnight (normally the LIBOR interest rate plus 2.5%).
              </p>
              <p className="lh-base fs-6">
                The trader buys 426 contracts at £23.50 per share, so their
                trading position is £10,011. Suppose that the share price of
                GlaxoSmithKline increases to £24.80 in 16 days. The initial
                value of the trade is £10,011, but the final value is
                £10,564.80.
              </p>
              <p className="lh-base fs-6">
                The trader’s profit (before charges and commission) is as
                follows:
              </p>
              <p className="lh-base fs-6">£10,564.80 – £10,011 = £553.80</p>
              <p className="lh-base fs-6">
                Since the commission is 0.1%, the trader pays £10 upon entering
                the position. Suppose that interest charges are 7.5%, which must
                be paid on each of the 16 days that the trader holds the
                position (426 × £23.50 × 0.075/365 = £2.06. Since the position
                is open for 16 days, the total charge is 16 × £2.06 = £32.92.).
              </p>
              <p className="lh-base fs-6">
                553.80 (profit) – 10 (commission) – 32.92 (interest) – 10
                (commission) = £500.88 (net profit)
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Advantages of CFDs</h3>
            <h4 className="pt-4 pb-4  ">Higher Leverage</h4>
            <div className="col-lg-12">
              CFDs provide higher leverage than traditional trading. Standard
              leverage in the CFD market is subject to regulation. It once was
              as low as a 2% maintenance margin (50:1 leverage) but is now
              limited in a range of 3% (30:1 leverage) and could go up to 50%
              (2:1 leverage). Lower margin requirements mean less capital outlay
              for the trader and greater potential returns. However, increased
              leverage can also magnify a trader’s losses.
            </div>
            <h3 className="pt-4 pb-4  ">
              Global Market Access from One Platform
            </h3>
            <div className="col-lg-12">
              Many CFD brokers offer products in all of the world’s major
              markets, allowing around-the-clock access. Investors can trade
              CFDs on a wide range of worldwide markets.
            </div>
            <h3 className="pt-4 pb-4  ">
              No Shorting Rules or Borrowing Stock
            </h3>
            <div className="col-lg-12">
              Certain markets have rules that prohibit shorting, require the
              trader to borrow the instrument before selling short, or have
              different margin requirements for short and long positions. CFD
              instruments can be shorted at any time without borrowing costs
              because the trader doesn’t own the underlying asset.
            </div>

            <h3 className="pt-4 pb-4  ">Professional Execution with No Fees</h3>

            <div className="col-lg-12">
              <p className="lh-base fs-6">
                CFD brokers offer many of the same order types as traditional
                brokers, including stops, limits, and contingent orders, such as
                “one cancels the other” and “if done.” Some brokers offering
                guaranteed stops will charge a fee for the service or recoup
                costs in another way.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Brokers make money when the trader pays the spread.
                Occasionally, they charge commissions or fees. To buy, a trader
                must pay the ask price, and to sell or short, the trader must
                pay the bid price. This spread may be small or large depending
                on the volatility of the underlying asset; fixed spreads are
                often available.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">No Day Trading Requirements</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Certain markets require minimum amounts of capital to day trade
                or place limits on the number of day trades that can be made
                within certain accounts. The CFD market is not bound by these
                restrictions, and all account holders can day trade if they
                wish. Accounts can often be opened for as little as $1,000,
                although $2,000 and $5,000 are common minimum deposit
                requirements.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Variety of Trading Opportunities</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Brokers currently offer stock, index, treasury, currency,
                sector, and commodity CFDs. This enables speculators interested
                in diverse financial vehicles to trade CFDs as an alternative to
                exchanges.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Disadvantages of CFDs</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                While CFDs offer an attractive alternative to traditional
                markets, they also present potential pitfalls. For one, having
                to pay the spread on entries and exits eliminates the potential
                to profit from small moves. The spread also decreases winning
                trades by a small amount compared to the underlying security and
                will increase losses by a small amount. So, while traditional
                markets expose the trader to fees, regulations, commissions, and
                higher capital requirements, CFDs trim traders’ profits through
                spread costs.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Weak Industry Regulation</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                The CFD industry is not highly regulated. A CFD broker’s
                credibility is based on reputation, longevity, and financial
                position rather than government standing or liquidity. There are
                excellent CFD brokers, but it’s important to investigate a
                broker’s background before opening an account.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Risks</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                CFD trading is fast-moving and requires close monitoring. As a
                result, traders should be aware of the significant risks when
                trading CFDs. There are liquidity risks and margins that you
                need to maintain; if you cannot cover reductions in values, then
                your provider may close your position, and you’ll have to meet
                the loss no matter what subsequently happens to the underlying
                asset.
              </p>
              <p className="lh-base fs-6">
                Leverage risks expose you to greater potential profits but also
                greater potential losses. While stop-loss limits are available
                from many CFD providers, they can’t guarantee that you won’t
                suffer losses, especially if there’s a market closure or a sharp
                price movement. Execution risks also may occur due to lags in
                trades.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Example of a CFD Trade</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Suppose that a stock has an ask price of $25.26 and the trader
                buys 100 shares. The cost of the transaction is $2,526 (plus any
                commission and fees). This trade requires at least $1,263 in
                free cash at a traditional broker in a 50% margin account, while
                a CFD broker requires just a 5% margin, or $126.30.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                A CFD trade will show a loss equal to the size of the spread at
                the time of the transaction. If the spread is 5 cents, the stock
                needs to gain 5 cents for the position to hit the breakeven
                price. While you’ll see a 5-cent gain if you owned the stock
                outright, you would have also paid a commission and incurred a
                larger capital outlay.
              </p>
              <p className="lh-base fs-6">
                If the stock rallies to a bid price of $25.76 in a traditional
                broker account, it can be sold for a $50 gain or $50/$1,263 =
                3.95% profit. However, when the national exchange reaches this
                price, the CFD bid price may only be $25.74. The CFD profit will
                be lower because the trader must exit at the bid price and the
                spread is larger than on the regular market.
              </p>
              <p className="lh-base fs-6">
                In this example, the CFD trader earns an estimated $48 or
                $48/$126.30 = 38% return on investment. The CFD broker may also
                require the trader to buy at a higher initial price—$25.28, for
                example. Even so, the $46 to $48 earned on the CFD trade denotes
                a net profit, while the $50 profit from owning the stock
                outright doesn’t include commissions or other fees. Thus, the
                CFD trader ends up with more money in their pocket.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">
              What are contracts for differences (CFDs)?
            </h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Contracts for differences (CFDs) are contracts between investors
                and financial institutions in which investors take a position on
                the future value of an asset. The difference between the open
                and closing trade prices are cash-settled. There is no physical
                delivery of goods or securities; a client and the broker
                exchange the difference in the initial price of the trade and
                its value when the trade is unwound or reversed.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">How do CFDs work?</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                A contract for difference (CFD) allows traders to speculate on
                the future market movements of an underlying asset, without
                actually owning or taking physical delivery of the underlying
                asset. CFDs are available for a range of underlying assets, such
                as shares, commodities, and foreign exchange.
              </p>
              <p className="lh-base fs-6">
                A CFD involves two trades. The first trade creates the open
                position, which is later closed out through a reverse trade with
                the CFD provider at a different price.
              </p>
              <p className="lh-base fs-6">
                If the first trade is a buy or long position, the second trade
                (which closes the open position) is a sell. If the opening trade
                was a sell or short position, the closing trade is a buy.
              </p>
              <p className="lh-base fs-6">
                The net profit of the trader is the price difference between the
                opening trade and the closing-out trade (less any commission or
                interest).
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Why are CFDs illegal in the U.S.?</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Part of the reason why CFDs are illegal in the U.S. is that they
                are an over-the-counter (OTC) product, which means that they
                don’t pass through regulated exchanges. Using leverage also
                allows for the possibility of larger losses and is a concern for
                regulators.
              </p>
              <p className="lh-base fs-6">
                The Commodity Futures Trading Commission (CFTC) and the
                Securities and Exchange Commission (SEC) prohibit residents and
                citizens of the U.S. from opening CFD accounts on domestic or
                foreign platforms.3
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">Is trading CFDs safe?</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Trading CFDs can be risky, and the potential advantages of them
                can sometimes overshadow the associated counterparty risk,
                market risk, client money risk, and liquidity risk. CFD trading
                can also be considered risky as a result of other factors,
                including poor industry regulation, potential lack of liquidity,
                and the need to maintain an adequate margin due to leveraged
                losses.
              </p>
            </div>

            <h3 className="pt-4 pb-4  ">Can you make money with CFDs?</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Yes, it is possible to make money trading CFDs. However, trading
                CFDs is a risky strategy relative to other forms of trading.
                Most successful CFD traders are veteran traders with a wealth of
                experience and tactical acumen.
              </p>
            </div>
            <h3 className="pt-4 pb-4  ">The Bottom Line</h3>
            <div className="col-lg-12">
              <p className="lh-base fs-6">
                Advantages to CFD trading include lower margin requirements,
                easy access to global markets, no shorting or day trading rules,
                and little or no fees. However, high leverage magnifies losses
                when they occur, and having to pay a spread to enter and exit
                positions can be costly when large price movements do not occur.
                Indeed, the European Securities and Markets Authority (ESMA) has
                placed restrictions on CFDs to protect retail investors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Whatcfd;
