import React from 'react'

function CenterSingapore() {
  return (
    <section className="">
    <div className="container py-5 ">
      <div className="col-lg-10 col-md-9 offset-md-1">
    
        <div class=" lh-base">
          <ul className=" lh-lg">
            <li className="mt-3 " style={{listStyle:"none"}}>
            Trading forex (currencies) in Singapore is popular among residents. Before any fx broker in Singapore can accept forex and CFD traders as clients, they must become authorised by the Monetary Authority of Singapore (MAS), which is the financial regulatory body in Singapore. MAS’s website is mas.gov.sg. We recommend Singaporean residents to follow the MAS on twitter, <a href='https://twitter.com/MAS_sg?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'> @MAS_sg.</a>
            </li>
         
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CenterSingapore