/* eslint-disable no-sparse-arrays */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Header from "./../components/Header";
import Footer from "./../components/Footer";

import Select from "react-select";
import { useState } from "react";
import { Link } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import Instance from "../utils/Interceptor";

const Allreview = () => {
  const countries = [
    { value: "AllCountry", label: "All Country" },
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antigua & Barbuda", label: "Antigua & Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Belgium", label: "Belgium" },
    { value: "Brazil", label: "Brazil" },
    { value: "China", label: "China" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Denmark", label: "Denmark" },
    { value: "Egypt", label: "Egypt" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Germany", label: "Germany" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Japan", label: "Japan" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Oman", label: "Oman" },
    { value: "Poland", label: "Poland" },
    { value: "Qatar", label: "Qatar" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Singapore", label: "Singapore" },
    { value: "South Africa", label: "South Africa" },
    { value: "Spain", label: "Spain" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
  ];

  const [searchValue, setSearchValue] = useState("");
  const [BrokerData, setBrokerData] = useState([]);
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [Loader, setLoader] = useState(false);

  const [SelectCountry, setSelectedCountry] = useState();

  const countryCodes = {
    AF: "Afghanistan",
    AE: "United Arab Emirates",

    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    BH: "Bahrain",
    BD: "Bangladesh",
    BE: "Belgium",
    BR: "Brazil",
    CN: "China",
    CY: "Cyprus",
    DK: "Denmark",
    EG: "Egypt",
    FI: "Finland",
    FR: "France",
    DE: "Germany",
    HK: "Hong Kong",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JP: "Japan",
    KW: "Kuwait",
    LU: "Luxembourg",
    NL: "Netherlands",
    NZ: "New Zealand",
    OM: "Oman",
    PL: "Poland",
    QA: "Qatar",
    SA: "Saudi Arabia",
    SG: "Singapore",
    ZA: "South Africa",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    UAE: "United Arab Emirates",
    GB: "United Kingdom",
    PK: "AllCountry",
    US: "AllCountry",
    RU: "AllCountry",
    TR: "AllCountry",
    IN: "AllCountry",
    CA: "AllCountry",
    MX: "AllCountry",
    NG: "AllCountry",
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await Instance.get("api/country-code");
        const country = response.data.country;
        const fullCountry = countryCodes[country];
        const condition =
          fullCountry && Object.values(countryCodes).includes(fullCountry)
            ? fullCountry
            : "AllCountry";
        setSelectedCountry(condition);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching country code:", error);
      }

      try {
        setLoader(true);
        const response = await Instance.get("api/broker");
        const brokerData = response.data.data.slice(0, 36);
        setBrokerData(brokerData);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching broker data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountry = (event) => {
    setSelectedCountry(event.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [BrokerData]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    filterBrokers(event.target.value);
  };

  const newdata = BrokerData.filter((broker) =>
    broker.country?.includes(SelectCountry)
  );

  console.log(newdata.slice(0, 3), "hellowrodl");
  console.log(filteredBrokers, "filteredBrokers");
  const filterBrokers = (searchText) => {
    const filteredResults = newdata.filter((broker) =>
      broker.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBrokers(filteredResults);
  };

  const minRating = 0.1;
  const maxRating = 5.0;
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "450px",
      margin: "0 auto",
      marginTop: "10px",
      "@media (max-width: 600px)": {
        width: "280px",
      },
      "@media (min-width: 1500px)": {
        width: "520px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1,
      marginLeft: "335px",
      width: "450px",
      "@media (max-width: 600px)": {
        width: "320px",
        marginLeft: "10px",
      },
    }),
  };

  return (
    <>
      <Header />
      {Loader ? (
        <div className="Loader"></div>
      ) : (
        <>
          <div className="container">
            <div className="row d-flex-justify-content-center py-4">
              <div className="col-md-12">
                <h1 className="text-bold text-center main-review-head py-2">
                  Broker reviews
                </h1>

                {/* <h2 className="text-bold text-center py-2">
              Find the right broker and invest on your own
            </h2> */}

                <Select
                  styles={selectStyles}
                  options={countries}
                  defaultValue={{ label: SelectCountry, value: SelectCountry }}
                  onChange={handleCountry}
                />

                <div className="review-select py-2">
                  <h6 className="text-bold ">Filter by name</h6>
                  <input
                    type="text"
                    className="form-control bn"
                    placeholder="Search by Name"
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <section
            className="brokerlist py-5"
            style={{ backgroundColor: "#ecf0f2" }}
          >
            <div className="container">
              <div className="row">
                <h2
                  className="text-center"
                  style={{ fontSize: "32px", fontWeight: "600" }}
                >
                  Brokers Available For {SelectCountry}
                </h2>

                <div className="row gy-5 gx-2 " style={{ marginLeft: "0px" }}>
                  {filteredBrokers.length === 0 &&
                    !searchValue &&
                    newdata?.map((e, i) => (
                      <div
                        key={i}
                        className="col-lg-4 pricing-block style-two animated fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1200ms"
                      >
                        <div
                          className="inner-box"
                          style={
                            e?.recommended === 0
                              ? { border: "0px", paddingTop: "28px" }
                              : {}
                          }
                        >
                          <div className="lower-content">
                            {e?.recommended === 1 ? (
                              <h5>{"Recommended"}</h5>
                            ) : (
                              <p>{""}</p>
                            )}

                            <h4 style={{ marginTop: "0px" }}>
                              <span
                                onClick={() => window.open(e?.url, "_blank")}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={e?.img}
                                  width={"77"}
                                  height={"77"}
                                  style={{
                                    borderRadious: "10px",
                                    boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                                  }}
                                  alt=""
                                  srcset=""
                                  className="mb-2 rounded-2"
                                />
                              </span>
                              <br />
                              <span style={{ fontSize: "15px" }}>
                                {e?.name}
                              </span>
                            </h4>
                            <div className="d-flex py-3 gap-2 justify-content-center  align-items-center setgap flex-reverse ">
                              <h3
                                className="mt-2"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {e?.ratting}
                              </h3>
                              <div className=" d-flex justify-content-center">
                                <div class="ratings">
                                  <div class="empty-stars"></div>
                                  <div
                                    className="full-stars"
                                    style={{
                                      width: `${
                                        ((e?.ratting - minRating) /
                                          (maxRating - minRating)) *
                                        100
                                      }%`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className={`py-2 mb-4`}
                                style={{ height: "92px" }}
                              >
                                <div className="grid grid-cols-2 text-sm">
                                  <div className="text-slate-600 text-left">
                                    {" "}
                                    Min. Deposit:
                                  </div>
                                  <div className="mr-4 flex">
                                    {" "}
                                    {e?.min_deposit}
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 text-sm">
                                  <div className="text-slate-600 text-left">
                                    {" "}
                                    Max. Leverage
                                  </div>
                                  <div className="mr-4 flex">
                                    {e?.max_leverage}{" "}
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 text-sm">
                                  <div className="text-slate-600 text-left">
                                    Trading Platform{" "}
                                  </div>

                                  <div className="mr-4 flex">{e?.platform}</div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="link-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                onClick={() =>
                                  window.open(`${e?.url}`, "_blank")
                                }
                                className="theme-btn btn-style-two"
                              >
                                <span className="btn-title ">
                                  VISIT BROKER{" "}
                                  <LuExternalLink
                                    size="22"
                                    style={{
                                      paddingBottom: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </span>
                              </span>
                            </div>
                            <div className="hint">
                              <Link to={e?.path} className="forhover">
                                {" "}
                                <span
                                  style={{ borderBottom: "1px solid #0a2cd4" }}
                                >
                                  Read review
                                </span>{" "}
                              </Link>
                              <p className="tradepara py-1 mt-2 mb-0">
                                {e?.lose}% of retail CFD accounts lose money
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {filteredBrokers.map((e, i) => (
                    <div
                      key={i}
                      className="col-lg-4 pricing-block style-two animated fadeInUp"
                      data-wow-delay="200ms"
                      data-wow-duration="1200ms"
                    >
                      <div
                        className="inner-box"
                        style={
                          e?.recommended === 0
                            ? { border: "0px", paddingTop: "28px" }
                            : {}
                        }
                      >
                        <div className="lower-content">
                          {e?.recommended === 1 ? (
                            <h5>{"Recommended"}</h5>
                          ) : (
                            <p>{""}</p>
                          )}
                          <h4 style={{ marginTop: "0px" }}>
                            <span
                              onClick={() => window.open(`${e?.url}`, "_blank")}
                              className="cursor-pointer"
                            >
                              <img
                                src={e?.img}
                                width={"77"}
                                height={"77"}
                                style={{
                                  borderRadious: "10px",
                                  boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                                }}
                                alt=""
                                srcset=""
                                className="mb-2 rounded-2"
                              />
                            </span>

                            <br />

                            {e?.name}
                          </h4>
                          <div className="d-flex py-3 gap-2 justify-content-center  align-items-center setgap flex-reverse ">
                            <h3
                              className="mt-2"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {e?.ratting}
                            </h3>
                            <div className=" d-flex justify-content-center">
                              <div class="ratings">
                                <div class="empty-stars"></div>
                                <div
                                  className="full-stars"
                                  style={{
                                    width: `${
                                      ((e?.ratting - minRating) /
                                        (maxRating - minRating)) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className={`py-2 mb-4`}
                              style={{ height: "92px" }}
                            >
                              <div className="grid grid-cols-2 text-sm">
                                <div className="text-slate-600 text-left">
                                  {" "}
                                  Min. Deposit:
                                </div>
                                <div className="mr-4 flex">
                                  {" "}
                                  {e?.min_deposit}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 text-sm">
                                <div className="text-slate-600 text-left">
                                  {" "}
                                  Max. Leverage
                                </div>
                                <div className="mr-4 flex">
                                  {e?.max_leverage}{" "}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 text-sm">
                                <div className="text-slate-600 text-left">
                                  Trading Platform{" "}
                                </div>

                                <div className="mr-4 flex">{e?.platform}</div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="link-btn"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              onClick={() => window.open(`${e?.url}`, "_blank")}
                              className="theme-btn btn-style-two cursor-pointer"
                            >
                              <span className="btn-title">
                                VISIT BROKER{" "}
                                <LuExternalLink
                                  size="22"
                                  style={{ paddingBottom: "5px" }}
                                />
                              </span>
                            </span>
                          </div>
                          <div className="hint">
                            <span className="forhover  cursor-pointer">
                              {" "}
                              <Link
                                to={e?.path}
                                className="forhover"
                                style={{ borderBottom: "1px solid #0a2cd4" }}
                              >
                                Read review
                              </Link>{" "}
                            </span>
                            <p className="tradepara py-1 mt-2 mb-0">
                              {e?.lose}% of retail CFD accounts lose money
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {searchValue && filteredBrokers.length === 0 && (
                    <p>We couldn`t find any brokers based on your search.</p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
};

export default Allreview;
