/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import activetrade from "./../assets/img/main-icon.jpg";
import plus500 from "./../assets/img/main-icon1.jpg";
import XTB from "./../assets/img/Xtb-logo.png";
import perperstone from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
import IG from "./../assets/img/brokerlogoclient/IG Logo.jpg";
// import capital from "./../assets/img/broker_logos/capitalcom-review.png";
import capital from "./../assets/img/brokerlogoclient/Capital.com logo.png";

import Fxpro from "./../assets/img/brokerlogoclient/Fxpro logo.svg";
import Forex from "./../assets/img/brokerlogoclient/forex.com logo.png";
import market from "./../assets/img/brokerlogoclient/Markets.com logo.jpeg";
import { LuExternalLink } from "react-icons/lu";
const BrokerTable = () => {
  const [option1, setoption1]=  useState('EURUSD')
  const handleChange = (event) => {
    setoption1(event.target.value);
  };
  return (
    <div className="container py-4">
      <h1
        className="text-center font-bold px-4  py-4"
        style={{
          fontSize: " 24px",
          lineHeight: "2.25rem",
          color: " #020617",
          fontFamily: " Geomanist,sans-serif",
        }}
      >
      
        Brokers by average spreads for normal or standard account
      </h1>
      <div className="container bkh-table-container">
        <div className="center-content">
          <div className="bkh-rating-table-content">
            <div className="bkh-rating-table">
              <table
                id="spreads_table_4231 d-flex justify-content-center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <tbody>
                  <tr className="maingap" style={{ gap: "62px" }}>
                    <th className="topBroker">BROKER</th>
                    <th
                      style={{
                        position: "relative",
                        right: "17px",
                      }}
                      id="table"
                    >
                      EURUSD
                    </th>
                    <th
                      style={{
                        position: "relative",
                        right: "17px",
                      }}
                      id="dropdown"
                    >
                      <select  onChange={handleChange}
                      className="form-select dropdwonleft"
                    aria-label="Default select example">
                      <option value="EURUSD" selected>EURUSD</option>
                      <option value="AUDUSD">AUDUSD</option>
                      <option value="EURGBP">EURGBP</option>
                      <option value="GBPUSD">GBPUSD</option>
                      <option value="USDJPY">USDJPY</option>
                      <option value="AUDJPY">AUDJPY</option>
                      <option value="EURJPY">EURJPY</option>
                      <option value="USDCAD">USDCAD</option>
                      </select>
                    </th>
                    
                    <th
                      className="mob-hide mb-none"
                      style={{
                        position: "relative",
                        right: "14px",
                      }}
                    >
                      AUDUSD
                    </th>
                    <th
                      className="mob-hide mb-none"
                      style={{
                        position: "relative",
                        right: "14px",
                      }}
                    >
                      EURGBP
                    </th>
                    <th
                      className="mob-hide mb-none"
                      style={{
                        position: "relative",
                        right: "10px",
                      }}
                    >
                      GBPUSD
                    </th>
                    <th
                      className="mob-hide mb-none"
                      style={{
                        position: "relative",
                        right: "10px",
                      }}
                    >
                      USDJPY
                    </th>
                    <th className="mob-hide mb-none desktop-none">AUDJPY</th>
                    <th className="mob-hide mb-none desktop-none">EURJPY</th>
                    <th className="mob-hide mb-none desktop-none">USDCAD</th>
                    <th className="visits">VISIT BROKER</th>
                  </tr>
                  

                  <tr>
                    <td className="">
                      <div className="bkh-logo ">
                        <small>
                          <span onClick={()=>window.open("https://www.activtrades.com","_blank")}>
                            <img
                              width={"40px"}
                              height={"40px"}
                              loading="lazy"
                              decoding="async"
                              style={{cursor:"pointer"}}
                              src={activetrade}
                              alt="pepperstone spreads logo"
                              className=" wp-bullet-lazy-loaded rounded-2"
                            />
                          </span>
                        </small>
                      </div>
                    </td>
                    <td className={option1 === "EURUSD" ? "mss-3 mb-block " : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                          
                          
                        }}
                      >
                        1.5
                      </small>
                    </td>
                    <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>

                      <small
                        style={{
                          backgroundColor: "#EE4242",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.8
                      </small>
                    </td>
                    <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        2.0
                      </small>
                    </td>
                    <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.6
                      </small>
                    </td>
                    <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.7
                      </small>
                    </td>
                    <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                      2.1
                      </small>
                    </td>
                    <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                    
                      <small
                        style={{
                          backgroundColor: "#EE4242 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                      2.5
                      </small>
                    </td>
                    <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#EE4242",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                      2.7
                      </small>
                    </td>
                    <td className="">
                      {" "}
                      <span
                
                        onClick={()=>window.open("https://www.activtrades.com", '_blank')}
                        className="theme-btn btn-style-two"
                        style={{cursor:"pointer"}}
                      >
                        <span
                          className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          VISIT BROKER{" "}
                          <div className="icon mb-none">
                            <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className=" ">
                    <td className="">
                      <div className="bkh-logo">
                        <small>
                          <span href="https://www.xtb.com" target="_blank" onClick={()=>window.open("https://www.xtb.com","_blank")}>
                            <img
                              width={"40px"}
                              height={"40px"}
                              loading="lazy"
                              decoding="async"
                              style={{cursor:"pointer"}}
                              src={XTB}
                              alt="ig markets spreads logo"
                              className=" wp-bullet-lazy-loaded rounded-2"
                            />
                          </span>
                        </small>
                      </div>
                    </td>
                    <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.0
                      </small>
                    </td>
                    <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.2
                      </small>
                    </td>
                    <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.5
                      </small>
                    </td>
                    <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.5
                      </small>
                    </td>
                    <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.5
                      </small>
                    </td>
                    <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.2
                      </small>
                    </td>

                    <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.8
                      </small>
                    </td>
                    <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        2.0
                      </small>
                    </td>

                    <td className="">
                      {" "}
                      <span
                        onClick={()=>window.open("https://www.xtb.com", '_blank')}
                        className="theme-btn btn-style-two"
                        style={{cursor:"pointer"}}
                      >
                        <span
                          className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          VISIT BROKER{" "}
                          <div className="icon mb-none">
                            <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="">
                      <div className="bkh-logo">
                        <small>
                          <span  onClick={()=>window.open("https://pepperstone.com","_blank")}>
                            <img
                              width={"40px"}
                              height={"40px"}
                              style={{cursor:"pointer"}}
                              loading="lazy"
                              decoding="async"
                              src={perperstone}
                              alt="capital markets spreads logo new"
                              className=" wp-bullet-lazy-loaded rounded-2"
                            />
                          </span>
                        </small>
                      </div>
                    </td>
                    <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.0
                      </small>
                    </td>
                    <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.2
                      </small>
                    </td>
                    <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: " #04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.4
                      </small>
                    </td>
                    <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.3
                      </small>
                    </td>
                    <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.2
                      </small>
                    </td>
                    <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: " #04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.7
                      </small>
                    </td>
                    <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.9
                      </small>
                    </td>
                    <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.6
                      </small>
                    </td>
                    <td className="">
                      {" "}
                      <span
                        className="theme-btn btn-style-two"
                        onClick={()=>window.open("https://pepperstone.com",'_blank')}
                        style={{cursor:"pointer"}}
                      >
                        <span
                          className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          VISIT BROKER{" "}
                          <div className="icon mb-none">
                            <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className="">
                    <td className="">
                      <div className="bkh-logo">
                        <small>
                          <span onClick={()=>window.open("https://www.plus500.com","_blank")}>
                            <img
                              width={"40px"}
                              height={"40px"}
                              loading="lazy"
                              decoding="async"
                              style={{cursor:"pointer"}}
                              alt="forex.com spreads logo"
                              className=" wp-bullet-lazy-loaded rounded-2"
                              src={plus500}
                            />
                          </span>
                        </small>
                      </div>
                    </td>
                    <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"} >
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.3
                      </small>
                    </td>
                    <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.1
                      </small>
                    </td>
                    <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.5
                      </small>
                    </td>
                    <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#EE4242",
                          borderRadius: "10px",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          justifyContent: "center",
                        }}
                      >
                        2.0
                      </small>
                    </td>
                    <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.7
                      </small>
                    </td>
                    <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        2.0
                      </small>
                    </td>
                    <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912",
                          borderRadius: "10px",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          justifyContent: "center",
                        }}
                      >
                        2.0
                      </small>
                    </td>
                    <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#EE4242",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        3.0
                      </small>
                    </td>
                    <td className="">
                      {" "}
                      <span
                        onClick={()=>window.open("https://www.plus500.com", '_blank')}
                        className="theme-btn btn-style-two"
                        style={{cursor:"pointer"}}
                      >
                        <span
                          className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          VISIT BROKER{" "}
                          <div className="icon mb-none">
                            <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className="">
                    <td className="">
                      <div className="bkh-logo">
                        <small>
                          <span onClick={()=>window.open("https://www.ig.com","_blank")}>
                            <img
                              width={"40px"}
                              height={"40px"}
                              loading="lazy"
                              decoding="async"
                              style={{cursor:"pointer"}}
                              src={IG}
                              alt="market spreads logo"
                              className=" wp-bullet-lazy-loaded rounded-2"
                            />
                          </span>
                        </small>
                      </div>
                    </td>
                    <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        0.7
                      </small>
                    </td>
                    <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        0.7
                      </small>
                    </td>
                    <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.2
                      </small>
                    </td>
                    <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.0
                      </small>
                    </td>
                    <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.1
                      </small>
                    </td>
                    <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#E1C912 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        2.1
                      </small>
                    </td>
                    <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#EE4242",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        2.1                      </small>
                    </td>
                    <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.3
                      </small>
                    </td>
                    <td className="">
                      {" "}
                      <span
                        onClick={()=>window.open("https://www.ig.com", '_blank')}
                        className="theme-btn btn-style-two"
                        style={{cursor:"pointer"}}
                      >
                        <span
                          className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          VISIT BROKER{" "}
                          <div className="icon mb-none">
                            <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>

             
                  <tr className=" ">
                  <td className="">
                    <div className="bkh-logo ">
                      <small>
                        <span  onClick={()=>window.open("https://www.forex.com","_blank")}>
                          <img
                            width={"40px"}
                            height={"40px"}
                            loading="lazy"
                            decoding="async"
                            style={{cursor:"pointer"}}
                            src={Forex}
                            alt="xm spreads logo"
                            className=" wp-bullet-lazy-loaded rounded-2"
                          />
                        </span>
                      </small>
                    </div>
                  </td>
                  <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.3
                    </small>
                  </td>
                  <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#E1C912 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.8
                    </small>
                  </td>
                  <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.4
                    </small>
                  </td>
                  <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.40
                    </small>
                  </td>
                  <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#E1C912 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.5
                    </small>
                  </td>

                  <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#E1C912 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      2.3
                    </small>
                  </td>
                  <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#ee4242",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      2.2
                    </small>
                  </td>
                  <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#e1c912",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.7
                    </small>
                  </td>
                  <td className="">
                    {" "}
                    <span
                      onClick={()=>window.open("https://www.forex.com", '_blank')}
                      className="theme-btn btn-style-two"
                      style={{cursor:"pointer"}}
                    >
                      <span
                        className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                        style={{ fontSize: "12px" }}
                      >
                        VISIT BROKER{" "}
                        <div className="icon mb-none">
                          <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                        </div>
                      </span>
                    </span>
                  </td>
                </tr>
                  <tr className="">
                    <td className="">
                      <div className="bkh-logo ">
                        <small>
                          <span  onClick={()=>window.open("https://www.fxpro.com","_blank")}>
                            <img
                              width={"40px"}
                              height={"40px"}
                              loading="lazy"
                              decoding="async"
                              style={{cursor:"pointer"}}
                              src={Fxpro}
                              alt="fxpro spreads logo new"
                              className=" wp-bullet-lazy-loaded rounded-2"
                            />
                          </span>
                        </small>
                      </div>
                    </td>
                    <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.2
                      </small>
                    </td>
                    <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#ee4242",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.9
                      </small>
                    </td>
                    <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.3
                      </small>
                    </td>
                    <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.5
                      </small>
                    </td>
                    <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64 ",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.3
                      </small>
                    </td>
                    <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#EE4242",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        3.1
                      </small>
                    </td>
                    <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.9
                      </small>
                    </td>
                    <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                      <small
                        style={{
                          backgroundColor: "#04cf64",
                          padding: "10px",
                          display: "flex",
                          height: "32px",
                          width: "105px",
                          borderRadius: "7px",
                          justifyContent: "center",
                        }}
                      >
                        1.7                      </small>
                    </td>
                    <td className="">
                      {" "}
                      <span
                        onClick={()=>window.open("https://www.fxpro.com",'_blank')}
                        className="theme-btn btn-style-two"
                        style={{cursor:"pointer"}}
                      >
                        <span
                          className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          VISIT BROKER{" "}
                          <div className="icon mb-none">
                            <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>

                
                  <tr className="">
                  <td className="">
                    <div className="bkh-logo ">
                      <small>
                        <span  onClick={()=>window.open("https://capital.com/","_blank")}>
                          <img
                            width={"40px"}
                            height={"40px"}
                            loading="lazy"
                            decoding="async"
                            style={{cursor:"pointer"}}
                            alt="fp markets spreads logo"
                            className=" wp-bullet-lazy-loaded"
                            src={capital}
                            rounded-2
                          />
                        </span>
                      </small>
                    </div>
                  </td>
                  <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      0.6
                    </small>
                  </td>
                  <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      0.6
                    </small>
                  </td>
                  <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#E1C912 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      2.0
                    </small>
                  </td>
                  <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.3
                    </small>
                  </td>
                  <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.0
                    </small>
                  </td>
                  <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      0.9
                    </small>
                  </td>
                  <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#ee4242",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      2.5
                    </small>
                  </td>
                  <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.9
                    </small>
                  </td>

                  <td className="">
                    {" "}
                    <span
                      onClick={()=>window.open("https://www.capital.com", '_blank')}
                      className="theme-btn btn-style-two"
                      style={{cursor:"pointer"}}
                    >
                      <span
                        className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                        style={{ fontSize: "12px" }}
                      >
                        VISIT BROKER{" "}
                        <div className="icon mb-none">
                          <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                        </div>
                      </span>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="">
                    <div className="bkh-logo ">
                      <small>
                        <span  onClick={()=>window.open("https://www.markets.com","_blank")}>
                          <img
                            width={"40px"}
                            height={"40px"}
                            loading="lazy"
                            decoding="async"
                            style={{cursor:"pointer"}}
                            src={market}
                            alt="hf markets logo for spreads provider"
                            className=" wp-bullet-lazy-loaded rounded-2"
                          />
                        </span>
                      </small>
                    </div>
                  </td>
                  <td className={option1 === "EURUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                    0.6
                    </small>
                  </td>
                  <td className={option1 === "AUDUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.0
                    </small>
                  </td>
                  <td className={option1 === "EURGBP" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.5
                    </small>
                  </td>
                  <td className={option1 === "GBPUSD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.5
                    </small>
                  </td>
                  <td className={option1 === "USDJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.0
                    </small>
                  </td>
                  <td className={option1 === "AUDJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      2.0
                    </small>
                  </td>
                  <td className={option1 === "EURJPY" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#04cf64 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.8
                    </small>
                  </td>
                   
                  <td className={option1 === "USDCAD" ? "mss-3 mb-block" : "mb-none"}>
                    <small
                      style={{
                        backgroundColor: "#E1C912 ",
                        padding: "10px",
                        display: "flex",
                        height: "32px",
                        width: "105px",
                        borderRadius: "7px",
                        justifyContent: "center",
                      }}
                    >
                      1.7
                    </small>
                  </td>
                  <td className="">
                    {" "}
                    <span
                      onClick={()=>window.open("https://www.markets.com/", '_blank')}
                      className="theme-btn btn-style-two"
                      style={{cursor:"pointer"}}
                    >
                      <span
                        className="btn-title2 d-flex align-item-center p-2 justify-content-center"
                        style={{ fontSize: "12px" }}
                      >
                        VISIT BROKER{" "}
                        <div className="icon mb-none">
                          <LuExternalLink  size='20' style={{paddingBottom:"5px"}} />
                        </div>
                      </span>
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerTable;
